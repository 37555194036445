<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">阅卷配置</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-container>
          <el-main>
            <div class="common-main-dev flexdc" style="align-items: flex-start;margin-bottom: 15px;">
              <div class="searchbox" style="margin-bottom: 15px;">
                <div title="考试名称" class="searchboxItem ci-full"><span class="itemLabel">考试名称:</span>
                  <div style="font-size:14px">{{examinationInfo.examinationName}}</div>
                </div>
                <div title="考试类型" class="searchboxItem ci-full"><span class="itemLabel">考试类型:</span>
                  <div style="font-size:14px">{{$setDictionary('EXAMINATION_EXAM_TYPE',examinationInfo.examType)}}</div>
                </div>
                <div title="试卷分数" class="searchboxItem ci-full"><span class="itemLabel">试卷分数:</span>
                  <div style="font-size:14px">{{examinationInfo.totalScore}}</div>
                </div>
              </div>
              <div class="searchbox" style="margin-bottom: 15px;">
                <div title="阅卷时是否显示考生信息" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:176px">阅卷时是否显示考生信息:</span>
                  <el-radio-group v-model="examinationInfo.scoringShowUser">
                    <el-radio :label="false">不显示</el-radio>
                    <el-radio :label="true">显示</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="searchbox" style="margin-bottom: 15px;">
                <div title="阅卷开始时间" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:176px">阅卷开始时间:</span>
                  <el-date-picker type="datetime" v-model="examinationInfo.scoringStartTime"
                                  placeholder="选择日期时间" size="mini" />
                </div>
              </div>
              <div class="searchbox" style="margin-bottom: 15px;">
                <div title="阅卷方式" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:176px">阅卷方式:</span>
                  <el-radio-group v-model="examinationInfo.scoringType">
                    <el-radio label="0">按人阅卷</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="searchbox" style="margin-bottom: 15px;">
                <div title="阅卷要求" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:176px">阅卷要求:</span>
                  <el-radio-group v-model="examinationInfo.scoringRequirement">
                    <el-radio label="SINGLE">单人阅卷</el-radio>
                    <el-radio label="MULTIPLE">多人阅卷</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="searchbox" style="margin-bottom: 15px;" v-if="'MULTIPLE' === examinationInfo.scoringRequirement">
                <div title="计分方式" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:176px">计分方式:</span>
                  <el-radio-group v-model="examinationInfo.scoringMode">
                    <el-radio :label="index" v-for="(item, index) in scoringModeDicts" :key="index">{{ item }}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="searchbox" style="margin-bottom: 15px;">
                <div title="提示" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:176px">提示:</span>
                  <span style="min-width:0rem">阅卷开始之后，配置信息将不能修改，请在阅卷开始时间之前确认阅卷信息</span>
                </div>
              </div>
              <div class="searchbox">
                <div title="阅卷老师" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:176px">阅卷老师:</span>
                  <el-button size="small" @click="markInfoAdd" type="primary">添加老师</el-button>
                </div>
              </div>
            </div>
            <el-form style="width: 900px;" ref="form" :model="form" label-width="176px">
              <div>
                <div v-for="(domain, index) in form.markInfoBOS" :key="index+domain.markId" class="activityItem">
                  <el-form-item label="阅卷老师" :prop="'markInfoBOS.' + index + '.adminId'" :rules="{
                                  required: true, message: '阅卷老师不能为空', trigger: 'change'
                              }" label-width="80px" class="form-item">
                    <el-select v-model="domain.adminId" @change="adminIdChange(domain)" placeholder="请选择" filterable size="small" style="width:150px">
                      <el-option v-for="item in adminIdList" :key="item.teacherId" :label="item.teacherName" :value="item.teacherId">
                      </el-option>
                    </el-select>
                    <el-button size="small" @click="markInfoDel(domain,index)" type="primary">删除</el-button>
                    <el-button size="small" @click="markInfoCheck(domain)" type="primary">选择学员</el-button>
                  </el-form-item>
                  <el-table ref="multipleTable" :data="domain.userList" size="mini" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
                    <el-table-column label="姓名" align="center" show-overflow-tooltip prop="userName" />
                    <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
                    <el-table-column label="考场" align="center" show-overflow-tooltip prop="classroomName" >
                      <template slot-scope="scope">{{scope.row.classroomName || '--'}}</template>
                    </el-table-column>
                    <el-table-column label="座位号" align="center" show-overflow-tooltip prop="seatNumber" >
                      <template slot-scope="scope">{{scope.row.seatNumber || '--'}}</template>
                    </el-table-column>
                    <el-table-column label="准考证号" align="center" show-overflow-tooltip prop="userCandidateNo" >
                      <template slot-scope="scope">{{scope.row.userCandidateNo || '--'}}</template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" show-overflow-tooltip>
                      <div slot-scope="scope" class="flexcc">
                        <el-button type="text" style="padding:0px 5px" size="mini" @click="delUser(scope.row)">删除</el-button>
                      </div>
                    </el-table-column>
                  </el-table>
                  <!-- <el-form-item  :prop="'markInfoBOS.' + index + '.userList'" :rules="{ type: 'array', required: true, message: '请至少选择一个学员或删除该阅卷老师', trigger: 'change' }" label-width="0" class="form-item"></el-form-item> -->
                </div>
              </div>
            </el-form>
          </el-main>
          <el-footer>
            <el-button @click="$router.back()">返回</el-button>
            <el-button style="margin-left: 40px;" type="primary" class="bgc-bv" @click="submitConfig()">保存</el-button>
          </el-footer>
        </el-container>
      </div>
    </div>
    <!-- 选择监考老师 - 弹框 -->
    <el-dialog v-dialogDrag :title="dialogData.dialogTitle" :visible.sync="dialogData.dialogState" v-if="dialogData.dialogState" :close-on-click-modal="false" width="800px" @close="closeModel">
      <div class="operationControl">
        <div class="searchbox">
          <div title="姓名" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width:3rem">姓名:</span>
            <el-input v-model="dialogData.userName" clearable size="small" placeholder="请输入姓名"></el-input>
          </div>
          <div title="手机号" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width:4rem">手机号:</span>
            <el-input v-model="dialogData.mobile" clearable size="small" placeholder="请输入手机号"></el-input>
          </div>
          <div class="df">
            <el-button type="primary" class="bgc-bv" round @click="dialogGetData()">搜索</el-button>
          </div>
        </div>
        <el-button type="primary" class="bgc-bv" round @click="dialogSubmit()">确认</el-button>
      </div>
      <el-table ref="multipleTable" :data="dialogData.tableData" row-key="examinationUserId" @selection-change="handleSelectionChange" height="500" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
        <el-table-column type="selection" align="center" reserve-selection />
        <el-table-column label="姓名" align="center" show-overflow-tooltip prop="userName" />
        <el-table-column label="身份证号" align="center" show-overflow-tooltip prop="idcard" />
        <el-table-column label="手机号" align="center" show-overflow-tooltip prop="mobile" />
        <el-table-column label="考场" align="center" show-overflow-tooltip prop="classroomName" >
          <template slot-scope="scope">{{scope.row.classroomName || '--'}}</template>
        </el-table-column>
        <el-table-column label="座位号" align="center" show-overflow-tooltip prop="seatNumber" >
          <template slot-scope="scope">{{scope.row.seatNumber || '--'}}</template>
        </el-table-column>
        <el-table-column label="准考证号" align="center" show-overflow-tooltip prop="userCandidateNo" >
          <template slot-scope="scope">{{scope.row.userCandidateNo || '--'}}</template>
        </el-table-column>
        <!-- <el-table-column label="角色类型" align="center" show-overflow-tooltip prop="roleName" /> -->
        <Empty slot="empty" />
      </el-table>
      <PageNum :apiData="dialogData.apiData" @sizeChange="dialogSizeChange" @getData="dialogGetData" />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_reviewPaperConfig",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  doNotInit: true,
  data () {
    return {
      examinationId: '',
      examinationInfo: {
        scoringShowUser: '',
        scoringStartTime: '',          //指定阅卷开始时间
        scoringType: '',               //阅卷方式: 按人/按题
        scoringRequirement: '',        //阅卷要求: 单人/多人
        scoringMode: '',               //计分方式：平均分/最高分/最低分
      },
      scoringModeDicts: [],
      form: {
        markInfoBOS: [
          {
            adminId: "",//阅卷老师id
            markId: "",//阅卷设置id
            userList: [],//考生列表
          }
        ],
      },
      adminIdList: [],
      dialogData: {
        dialogTitle: '选择学员',
        dialogState: false,
        scoringRequirement: '',
        adminId: '',
        markId: '',
        userName: '',
        mobile: '',
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        apiData: {
          total: 0,
          size: 10,
          current: 1,
        },
        multipleSelection: []
      },
    };
  },
  created () {
    this.examinationId = this.$route.query.examinationId
    this.getDictionaries()
    this.getInfo('INIT');
    this.getTeacher()
  },
  mounted () {
  },
  watch: {
    'examinationInfo.scoringRequirement': function (newValue, oldValue) {
      //从多人阅卷模式 更换为 单人阅卷模式时, 要求用户确认,确认后清除阅卷分配信息
      if ('SINGLE' === newValue && 'MULTIPLE' === oldValue) {
        this.$confirm("多人阅卷模式变更为单人阅卷模式将清除所有阅卷配置,您是否确认切换", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          //确认清除时,调用服务端接口操作
          this.$post('/biz/examination/mark/clean', {
            examinationId: this.examinationId,
          }, 3000, true, 6).then((res) => {
            this.$message.success('清除成功！');
            this.form = { markInfoBOS: [] };
          }).catch(() => {
            //执行失败依然为多人阅卷模式
            this.examinationInfo.scoringRequirement = 'MULTIPLE';
          });
        }).catch(() => {
          //取消确认时依然为多人阅卷模式
          this.examinationInfo.scoringRequirement = 'MULTIPLE';
        });
      }
    },
  },
  methods: {
    getDictionaries() {
      this.scoringModeDicts = this.$setDictionary("EXAMINATION_SCORING_MODE", "list") || [];
    },
    getInfo (state) {
      this.$post('/biz/examination/mark/getSettingInfo', { examinationId: this.examinationId }, 3000, true, 6)
        .then((res) => {
          //仅初始化加载时,对考试信息赋值
          if ('INIT' === state) {
            this.examinationInfo = res.data.examination;
          }
          this.form.markInfoBOS = res.data.markInfoBOS;
        })
    },
    getTeacher () {
      this.$post('/biz/exam/teacher/list', { teacherRoleType:'10',examinationId: this.examinationId })
        .then((res) => {
          this.adminIdList = res.data
        })
    },
    // 新增阅卷老师
    markInfoAdd () {
      this.form.markInfoBOS.unshift({
        adminId: "",//阅卷老师id
        markId: "",//阅卷设置id
        userList: [],//考生列表
      })
    },
    // 修改阅卷老师
    adminIdChange (item) {
      const { markId, adminId } = item
      if (!markId) {
        return
      }
      this.$post('/biz/examination/mark/modifyTeacher', {
        examinationId: this.examinationId,
        adminId,
        markId,
      }, 3000, true, 6).then((res) => {
        this.$message.success('修改老师成功！')
      }).catch(() => {
        this.getInfo();
      })
    },
    // 删除阅卷老师
    markInfoDel (item, index) {
      const { markId } = item
      if (!markId) {
        this.form.markInfoBOS.splice(index, 1)
        return
      }
      this.$confirm('是否删除该阅卷老师', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$post('/biz/examination/mark/deleteMark', {
          examinationId: this.examinationId,
          markId,
        }, 3000, true, 6).then((res) => {
          this.$message.success('删除老师成功！')
          this.getInfo()
        })
      }).catch(() => { });
    },
    // 删除学员
    delUser (row) {
      this.$confirm('是否删除该学员', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$post('/biz/examination/mark/removeUser', {
          examinationId: this.examinationId,
          markUserIds: [row.markUserId],
        }, 3000, true, 6).then((res) => {
          this.$message.success('删除学员成功！')
          this.getInfo()
        })
      }).catch(() => { });
    },
    // 保存
    submitConfig() {
      const flag = this.form.markInfoBOS.findIndex(e=>e.userList.length===0)
      if(flag!=-1){
        this.$message.error('阅卷老师下的学员不能为空！')
        return
      }
      if (!this.examinationInfo.scoringStartTime) {
        this.$message.error("请选择阅卷开始时间!");
        return;
      } else {
        this.examinationInfo.scoringStartTime = this.$moment(this.examinationInfo.scoringStartTime)
            .format("YYYY-MM-DD HH:mm:ss");
      }
      if (!['MULTIPLE', 'SINGLE'].includes(this.examinationInfo.scoringRequirement)) {
        this.$message.error('请选择阅卷要求！')
        return;
      }
      if ('MULTIPLE' === this.examinationInfo.scoringRequirement) {
        if (!['10','20','30'].includes(this.examinationInfo.scoringMode)) {
          this.$message.error('请选择计分方式');
          return;
        }
      }
      this.$post('/biz/examination/mark/modifyMarkSetting', this.examinationInfo, 3000, true, 6).then((res) => {
          this.$message.success('保存成功')
          setTimeout(()=>{
            this.$router.back()
          },300)
        })
    },
    // 弹窗start
    closeModel () {
      this.dialogData = this.$options.data().dialogData
    },
    handleSelectionChange (val) {
      this.dialogData.multipleSelection = val;
    },
    // 选择学员
    async markInfoCheck (item) {
      const { adminId, markId } = item
      if (!adminId) {
        this.$message.error('请选择阅卷老师！')
        return
      }
      this.dialogData.adminId = adminId
      this.dialogData.markId = markId
      try {
        await this.dialogGetData()
      } catch (error) {
        console.log(error);
      }
      this.dialogData.dialogState = true
    },
    dialogSizeChange (pageSize) {
      this.dialogData.pageSize = pageSize;
      this.dialogGetData();
    },
    dialogGetData (pageNum = 1) {
      return this.$post('/biz/examination/mark/selectChoiceUserList', {
        pageNum: pageNum,
        pageSize: this.dialogData.pageSize,
        examinationId: this.examinationId,
        userName: this.dialogData.userName,
        mobile: this.dialogData.mobile,
        adminId:  this.dialogData.adminId,
        scoringRequirement: this.examinationInfo.scoringRequirement,
      }, 3000, true, 6).then((res) => {
        this.dialogData.tableData = res.data.list
        this.dialogData.apiData = {
          total: res.data.total || 0,
          size: this.dialogData.pageSize || 10,
          current: this.dialogData.pageNum || 1,
        };
      })
    },
    // 新增/编辑 - 确认
    dialogSubmit () {
      if (this.dialogData.multipleSelection.length === 0) {
        this.$message.error('请选择学员后提交！')
        return
      }
      const examinationUserIds = this.dialogData.multipleSelection.map(e => e.examinationUserId)
      const data = {
        examinationId: this.examinationId,
        adminId: this.dialogData.adminId,
        markId: this.dialogData.markId,
        scoringRequirement: this.examinationInfo.scoringRequirement,
        scoringMode: this.examinationInfo.scoringMode,
        examinationUserIds
      }
      this.$post('/biz/examination/mark/add', data, 3000, true, 6)
        .then((res) => {
          if (res.status == 0) {
            this.closeModel()
            this.$message.success(res.message);
            this.getInfo()
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 弹窗end
  },
};
</script>
<style lang="less" scoped>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: '\e6df' !important;
  }
}
.activityItem {
  background: #ccc;
  padding: 20px;
  margin-left: 176px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 724px;
  position: relative;
  /deep/ .el-form-item__content .el-select {
    float: left;
  }
  /deep/ .el-form-item__content .el-button {
    float: right;
  }
  /deep/ .el-form-item__content .el-button + .el-button {
    margin-right: 15px;
  }
}
.el-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-main-dev {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
}
.common-main-dev .searchboxItem {
  margin-right: 10px;
}
.common-main-dev > div {
  display: flex;
  overflow: hidden;
}
.common-main-dev > div > div > span {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
</style>
